/*------------------------------------------------
// File Name:PDM_BodyCheck_Tags.js
// File Description:PDM_BodyCheck_Tags API方法js接口
// Author:zxl
// Create Time:2023/04/04 17:28:46
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckTags: "/PDM_BodyCheck_Tags"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_BodyCheck_TagsEntity(parameter) {
    return axios({
        url: api._bodycheckTags + "/CreatePDM_BodyCheck_TagsEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_BodyCheck_TagsList(parameter) {
    return axios({
        url: api._bodycheckTags + "/GetPDM_BodyCheck_TagsList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_BodyCheck_TagsListByPage(parameter) {
    return axios({
        url: api._bodycheckTags + "/GetPDM_BodyCheck_TagsListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表--套餐编辑页面拉取未选择的标签---------------
export function GetPDM_BodyCheck_TagsListByPage2(parameter) {
    return axios({
        url: api._bodycheckTags + "/GetPDM_BodyCheck_TagsListByPage2",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_BodyCheck_TagsEntity(parameter) {
    return axios({
        url: api._bodycheckTags + "/GetPDM_BodyCheck_TagsEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_BodyCheck_TagsEntity(parameter) {
    return axios({
        url: api._bodycheckTags+ "/UpdatePDM_BodyCheck_TagsEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_BodyCheck_TagsEntity(parameter) {
    return axios({
        url: api._bodycheckTags + "/DeletePDM_BodyCheck_TagsEntity",
        method: 'post',
        data: parameter
    })
}
