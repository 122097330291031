/*------------------------------------------------
// File Name:PDM_BodyCheck_Item.js
// File Description:PDM_BodyCheck_Item API方法js接口
// Author:zxl
// Create Time:2023/04/17 21:05:54
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckItem: "/PDM_BodyCheck_Item"
}

export default api


//-------------------创建套餐项目表对象-------------------
export function CreatePDM_BodyCheck_ItemEntity(parameter) {
    return axios({
        url: api._bodycheckItem + "/CreatePDM_BodyCheck_ItemEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------同步套餐项目-------------------
export function SyncItem(parameter) {
    return axios({
        url: api._bodycheckItem + "/SyncItem",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐项目表列表-------------------
export function GetPDM_BodyCheck_ItemList(parameter) {
    return axios({
        url: api._bodycheckItem + "/GetPDM_BodyCheck_ItemList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取套餐项目表列表---------------
export function GetPDM_BodyCheck_ItemListByPage(parameter) {
    return axios({
        url: api._bodycheckItem + "/GetPDM_BodyCheck_ItemListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐项目表对象---------------
export function GetPDM_BodyCheck_ItemEntity(parameter) {
    return axios({
        url: api._bodycheckItem + "/GetPDM_BodyCheck_ItemEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存套餐项目表对象---------------
export function UpdatePDM_BodyCheck_ItemEntity(parameter) {
    return axios({
        url: api._bodycheckItem+ "/UpdatePDM_BodyCheck_ItemEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉套餐项目表对象---------------
export function DeletePDM_BodyCheck_ItemEntity(parameter) {
    return axios({
        url: api._bodycheckItem + "/DeletePDM_BodyCheck_ItemEntity",
        method: 'post',
        data: parameter
    })
}
