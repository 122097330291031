/*------------------------------------------------
// File Name:PDM_BodyCheck_TaoCanTags.js
// File Description:PDM_BodyCheck_TaoCanTags API方法js接口
// Author:zxl
// Create Time:2023/04/17 17:30:19
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckTaocantags: "/PDM_BodyCheck_TaoCanTags"
}

export default api


//-------------------创建套餐标签中间表对象-------------------
export function CreatePDM_BodyCheck_TaoCanTagsEntity(parameter) {
    return axios({
        url: api._bodycheckTaocantags + "/CreatePDM_BodyCheck_TaoCanTagsEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐标签中间表列表-------------------
export function GetPDM_BodyCheck_TaoCanTagsList(parameter) {
    return axios({
        url: api._bodycheckTaocantags + "/GetPDM_BodyCheck_TaoCanTagsList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取套餐标签中间表列表---------------
export function GetPDM_BodyCheck_TaoCanTagsListByPage(parameter) {
    return axios({
        url: api._bodycheckTaocantags + "/GetPDM_BodyCheck_TaoCanTagsListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐标签中间表对象---------------
export function GetPDM_BodyCheck_TaoCanTagsEntity(parameter) {
    return axios({
        url: api._bodycheckTaocantags + "/GetPDM_BodyCheck_TaoCanTagsEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存套餐标签中间表对象---------------
export function UpdatePDM_BodyCheck_TaoCanTagsEntity(parameter) {
    return axios({
        url: api._bodycheckTaocantags+ "/UpdatePDM_BodyCheck_TaoCanTagsEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉套餐标签中间表对象---------------
export function DeletePDM_BodyCheck_TaoCanTagsEntity(parameter) {
    return axios({
        url: api._bodycheckTaocantags + "/DeletePDM_BodyCheck_TaoCanTagsEntity",
        method: 'post',
        data: parameter
    })
}
