<template>
  <q-card style="width: 1500px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        编辑套餐
        <small class="q-ml-sm text-size-sm">
          <!-- {{ PdmBodycheckTaocanEntity.PdmBodycheckTaocanName }}({{
                            PdmBodycheckTaocanEntity.PdmBodycheckTaocanID
                        }}) -->
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm q-pr-sm">
        <!-- <q-input
                        label="机构ID" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmBodycheckTaocanEntity.HospID "
                    /> -->
        <q-input
          label="套餐编码"
          dense
          outlined
          readonly
          class="col-1"
          v-model="PdmBodycheckTaocanEntity.TaoCan_Code"
        />
        <q-input
          label="套餐名称"
          dense
          outlined
          class="col-3"
          v-model="PdmBodycheckTaocanEntity.TaoCan_Name"
        />
        <q-input
          label="套餐原价金额"
          dense
          outlined
          class="col-2"
          v-model="PdmBodycheckTaocanEntity.Amount"
        />
        <q-input
          label="套餐打折金额"
          dense
          outlined
          class="col-2"
          v-model="PdmBodycheckTaocanEntity.SaleAmount"
        />
        <q-input
          label="已约底数"
          dense
          outlined
          class="col-1"
          v-model="PdmBodycheckTaocanEntity.TaoCan_YiYue"
        />
        <q-input
          label="排序"
          dense
          outlined
          class="col-1"
          v-model="PdmBodycheckTaocanEntity.TaoCan_Sort"
        />
        <q-btn-toggle
          v-model="PdmBodycheckTaocanEntity.IsHot"
          push
          glossy
          toggle-color="primary"
          :options="[
            { label: '不是HOT', value: 0 },
            { label: '是HOT', value: 1 },
          ]"
        />
        <q-select
          label="分类选择"
          filled
          dense
          v-model="PdmBodycheckTaocanEntity.FenLei_ID"
          :options="TaocanFenLeiList"
          option-value="FenLei_ID"
          option-label="FenLei_Name"
          emit-value
          map-options
          style="min-width: 250px; max-width: 300px"
        />
        <q-card class="col-12 my-card" bordered>
          <div class="row q-my-xs">
            <div class="col-3">
              <div class="text-subtitle1 q-pl-sm">套餐标签：</div>
            </div>
            <div class="col-9 q-pr-sm">
              <q-btn
                style="float:right"
                color="primary"
                size="11px"
                label="添加"
                @click="AddTags"
              />
            </div>
          </div>

          <q-separator />
          <q-chip
            v-for="item in TaocanTagsList"
            :key="item.TaoCanTags_ID"
            removable
            @remove="onDelTaocanTags(item)"
            color="positive"
            text-color="white"
          >
            {{ item.Tags_Name }}
          </q-chip>
        </q-card>

        <q-card class="col-12" bordered>
          <div class="row q-my-xs">
            <div class="col-3">
              <div class="text-subtitle1 q-pl-sm">套餐项目：</div>
            </div>
            <div class="col-9 q-pr-sm">
              <q-btn
                style="float:right"
                color="primary"
                size="11px"
                label="添加"
                @click="AddItem"
              />
            </div>
          </div>

          <q-separator />
          <q-chip
            v-for="item in TaocanItemList"
            :key="item.TaoCanItem_ID"
            removable
            @remove="onDelTaocanItem(item)"
            color="secondary"
            text-color="white"
          >
            {{ item.Item_Name }}
          </q-chip>
        </q-card>

        <!-- 添加图片 -->
        <div class="col-12">
          <div class="row">
            <div class="col-4 q-mr-xs">
              <q-card bordered>
                <div class="q-my-xs">
                  <div class="row">
                    <div class="col-9">
                      <div class="text-subtitle1 q-pl-sm">套餐列表图片(164x164)：</div>
                    </div>
                    <div class="col-3 q-pr-sm">
                      <q-btn
                        style="float:right"
                        color="primary"
                        size="11px"
                        label="上传"
                        @click="onListFiles"
                      />
                    </div>
                  </div>
                </div>
                <q-separator />
                <q-img
                  :src="
                    PdmBodycheckTaocanEntity.Api_Url +
                      '/UploadImage/TaoCan/' +
                      PdmBodycheckTaocanEntity.HospID +
                      '/' +
                      PdmBodycheckTaocanEntity.TaoCan_ListImages
                  "
                  spinner-color="white"
                  style="height: 200px; max-width: 370px"
                />
              </q-card>
            </div>
            <div class="col-4 q-ml-xs">
              <q-card bordered>
                <div class="q-my-xs">
                  <div class="row">
                    <div class="col-9">
                      <div class="text-subtitle1 q-pl-sm">套餐详情图片(750x400)：</div>
                    </div>
                    <div class="col-3 q-pr-sm">
                      <q-btn
                        style="float:right"
                        color="primary"
                        size="11px"
                        label="上传"
                        @click="onDetailsFiles"
                      />
                    </div>
                  </div>
                </div>
                <q-separator />
                <q-img
                  :src="
                    PdmBodycheckTaocanEntity.Api_Url +
                      '/UploadImage/TaoCan/' +
                      PdmBodycheckTaocanEntity.HospID +
                      '/' +
                      PdmBodycheckTaocanEntity.TaoCan_DetailsImages
                  "
                  spinner-color="white"
                  style="height: 200px; max-width: 370px"
                />
              </q-card>
            </div>
          </div>
          <!-- 上传图片控件-列表图片用的 -->
          <q-uploader
            label="Auto Uploader"
            ref="ListFiles"
            v-show="false"
            auto-upload
            :url="
              PdmBodycheckTaocanEntity.Api_Url +
                '/api/PDM_BodyCheck_TaoCan/UploadImage_List'
            "
            accept=".jpg, image/*"
            @uploaded="onUploaded_List"
            multiple
            :headers="[
              { name: 'HospID', value: PdmBodycheckTaocanEntity.HospID },
            ]"
          />

          <!-- 上传图片控件-详情图片用的 -->
          <q-uploader
            label="Auto Uploader"
            ref="DetailsFiles"
            v-show="false"
            auto-upload
            :url="
              PdmBodycheckTaocanEntity.Api_Url +
                '/api/PDM_BodyCheck_TaoCan/UploadImage_Details'
            "
            accept=".jpg, image/*"
            @uploaded="onUploaded_Details"
            multiple
            :headers="[
              { name: 'HospID', value: PdmBodycheckTaocanEntity.HospID },
            ]"
          />
        </div>

        <!-- 体检流程 -->
        <q-card class="col-12" bordered>
          <div class="text-subtitle1 q-pl-sm">体检流程：</div>
          <q-separator inset />
          <q-editor
            :dense="$q.screen.lt.md"
            v-model="PdmBodycheckTaocanEntity.LiuCheng"
            :toolbar="toolbar"
            :fonts="fonts"
          />
        </q-card>

        <!-- 体检须知 -->
        <q-card class="col-12" bordered>
          <div class="text-subtitle1 q-pl-sm">体检须知：</div>
          <q-separator inset />
          <q-editor
            :dense="$q.screen.lt.md"
            v-model="PdmBodycheckTaocanEntity.XuZhi"
            :toolbar="toolbar"
            :fonts="fonts"
          />
        </q-card>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>

  <q-dialog v-model="taocanTagsShowDlg" persistent>
    <TaoCanTags @onLoadTaoCanTags="onLoadTaoCanTags" />
  </q-dialog>
  <q-dialog v-model="taocanItemShowDlg" persistent>
    <TaoCanItem @onLoadTaoCanItem="onLoadTaoCanItem" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { useQuasar } from "quasar";
import TaoCanTags from "./taoCanTags.vue";
import TaoCanItem from "./taoCanItem.vue";
import * as BodycheckTaoCantags from "@/api/pdm/bodycheckTaocantags";
import * as BodycheckTaocan from "@/api/pdm/bodycheckTaocan";
import * as BodycheckTaocanitem from "@/api/pdm/bodycheckTaocanitem";
import * as BodycheckFenlei from "@/api/pdm/bodycheckFenlei";
export default {
  components: {
    TaoCanTags,
    TaoCanItem,
  },
  props: {},
  emits: ["onLoadData"],
  computed: {
    ...mapGetters("appUser", ["hospID", "ImagesUrl"]),
    ...mapState("PdmBodycheckTaocan", [
      "PdmBodycheckTaocanShowDlg",
      "PdmBodycheckTaocanEntity",
      "PdmBodycheckTaocanList",
      "TaocanTagsShowDlg",
      "TaocanItemShowDlg",
    ]),
    taocanTagsShowDlg: {
      get() {
        return this.TaocanTagsShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmBodycheckTaocan/setTaocanTagsShowDlg", val);
      },
    },

    taocanItemShowDlg: {
      get() {
        return this.TaocanItemShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmBodycheckTaocan/setTaocanItemShowDlg", val);
      },
    },
  },
  data() {
    return {
      TaocanTagsList: null,
      TaocanItemList: null,
      TaocanFenLeiList:null,
      $q: useQuasar(),
      toolbar: [
        [
          {
            label: this.$q.lang.editor.align,
            icon: this.$q.iconSet.editor.align,
            fixedLabel: true,
            list: "only-icons",
            options: ["left", "center", "right", "justify"],
          },
          {
            label: this.$q.lang.editor.align,
            icon: this.$q.iconSet.editor.align,
            fixedLabel: true,
            options: ["left", "center", "right", "justify"],
          },
        ],
        ["bold", "italic", "strike", "underline", "subscript", "superscript"],
        ["token", "hr", "link", "custom_btn"],
        ["print", "fullscreen"],
        [
          {
            label: this.$q.lang.editor.formatting,
            icon: this.$q.iconSet.editor.formatting,
            list: "no-icons",
            options: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "code"],
          },
          {
            label: this.$q.lang.editor.fontSize,
            icon: this.$q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: "no-icons",
            options: [
              "size-1",
              "size-2",
              "size-3",
              "size-4",
              "size-5",
              "size-6",
              "size-7",
            ],
          },
          {
            label: this.$q.lang.editor.defaultFont,
            icon: this.$q.iconSet.editor.font,
            fixedIcon: true,
            list: "no-icons",
            options: [
              "default_font",
              "arial",
              "arial_black",
              "comic_sans",
              "courier_new",
              "impact",
              "lucida_grande",
              "times_new_roman",
              "verdana",
            ],
          },
          "removeFormat",
        ],
        ["quote", "unordered", "ordered", "outdent", "indent"],

        ["undo", "redo"],
        ["viewsource"],
      ],
      fonts: {
        arial: "Arial",
        arial_black: "Arial Black",
        comic_sans: "Comic Sans MS",
        courier_new: "Courier New",
        impact: "Impact",
        lucida_grande: "Lucida Grande",
        times_new_roman: "Times New Roman",
        verdana: "Verdana",
      },
    };
  },
  mounted() {
    this.onLoadTaoCanTags();
    this.onLoadTaoCanItem();
    this.onLoadTaoCanFenLei();
  },
  methods: {
    ...mapActions("PdmBodycheckTaocan", [
      "actUpdatePdmBodycheckTaocan",
      "actGetPdmBodycheckTaocanList",
    ]),
    onLoadTaoCanFenLei() {
      BodycheckFenlei.GetPDM_BodyCheck_FenLeiList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      }).then((res) => {
        if (res.Code == 0) {
          this.TaocanFenLeiList = res.Data;
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onLoadTaoCanTags() {
      BodycheckTaoCantags.GetPDM_BodyCheck_TaoCanTagsList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        TaoCan_ID: this.PdmBodycheckTaocanEntity.TaoCan_ID,
      }).then((res) => {
        if (res.Code == 0) {
          this.TaocanTagsList = res.Data;
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onLoadTaoCanItem() {
      BodycheckTaocanitem.GetPDM_BodyCheck_TaoCanItemList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        TaoCan_ID: this.PdmBodycheckTaocanEntity.TaoCan_ID,
      }).then((res) => {
        if (res.Code == 0) {
          this.TaocanItemList = res.Data;
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onDelTaocanItem(item) {
      BodycheckTaocanitem.DeletePDM_BodyCheck_TaoCanItemEntity({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: item,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadTaoCanItem();
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onDelTaocanTags(item) {
      BodycheckTaoCantags.DeletePDM_BodyCheck_TaoCanTagsEntity({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: item,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadTaoCanTags();
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onOK() {
      BodycheckTaocan.UpdatePDM_BodyCheck_TaoCanEntity2({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmBodycheckTaocanEntity,
      })
        .then((res) => {
          if (res.Code == 0) {
            this.$emit("onLoadData");
            this.msg.success(res.Message);
          } else {
            this.msg.error(res.Message);
          }
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckTaocan/setPdmBodycheckTaocanShowDlg2",
            false
          );
        });
    },
    AddTags() {
      this.$store.commit("PdmBodycheckTaocan/setTaocanTagsShowDlg", true);
    },
    AddItem() {
      this.$store.commit("PdmBodycheckTaocan/setTaocanItemShowDlg", true);
    },
    onDetailsFiles() {
      this.$refs.DetailsFiles.pickFiles();
      this.$refs.DetailsFiles.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onListFiles() {
      this.$refs.ListFiles.pickFiles();
      this.$refs.ListFiles.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onUploaded_List(info) {
      var ReturnSign = JSON.parse(info.xhr.responseText).ReturnSign;
      this.PdmBodycheckTaocanEntity.TaoCan_ListImages = ReturnSign.filesname;
    },
    onUploaded_Details(info) {
      var ReturnSign = JSON.parse(info.xhr.responseText).ReturnSign;
      this.PdmBodycheckTaocanEntity.TaoCan_DetailsImages = ReturnSign.filesname;
    },
  },
};
</script>
