/*------------------------------------------------
// File Name:PDM_BodyCheck_TaoCan.js
// File Description:PDM_BodyCheck_TaoCan API方法js接口
// Author:zxl
// Create Time:2023/04/07 17:42:04
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckTaocan: "/PDM_BodyCheck_TaoCan"
}

export default api


//-------------------创建套餐表对象-------------------
export function CreatePDM_BodyCheck_TaoCanEntity(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/CreatePDM_BodyCheck_TaoCanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐表列表-------------------
export function GetPDM_BodyCheck_TaoCanList(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/GetPDM_BodyCheck_TaoCanList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取套餐表列表---------------
export function GetPDM_BodyCheck_TaoCanListByPage(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/GetPDM_BodyCheck_TaoCanListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐表对象---------------
export function GetPDM_BodyCheck_TaoCanEntity(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/GetPDM_BodyCheck_TaoCanEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存套餐表对象---------------
export function UpdatePDM_BodyCheck_TaoCanEntity(parameter) {
    return axios({
        url: api._bodycheckTaocan+ "/UpdatePDM_BodyCheck_TaoCanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------保存套餐表对象---------------
export function UpdatePDM_BodyCheck_TaoCanEntity2(parameter) {
    return axios({
        url: api._bodycheckTaocan+ "/UpdatePDM_BodyCheck_TaoCanEntity2",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉套餐表对象---------------
export function DeletePDM_BodyCheck_TaoCanEntity(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/DeletePDM_BodyCheck_TaoCanEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取导入套餐表列表---------------
export function Getv_boxitem_wxyy(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/Getv_boxitem_wxyy",
        method: 'post',
        data: parameter
    })
}

//-------------------修改上架/下架---------------
export function UpateIsDel(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/UpateIsDel",
        method: 'post',
        data: parameter
    })
}

//-------------------获取IsDel=0的套餐数据---------------
export function GetPDM_BodyCheck_TaoCanIsDelList(parameter) {
    return axios({
        url: api._bodycheckTaocan + "/GetPDM_BodyCheck_TaoCanIsDelList",
        method: 'post',
        data: parameter
    })
}

