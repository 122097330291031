/*------------------------------------------------
// File Name:PDM_BodyCheck_TaoCanItem.js
// File Description:PDM_BodyCheck_TaoCanItem API方法js接口
// Author:zxl
// Create Time:2023/04/18 15:43:18
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckTaocanitem: "/PDM_BodyCheck_TaoCanItem"
}

export default api


//-------------------创建套餐项目中间表对象-------------------
export function CreatePDM_BodyCheck_TaoCanItemEntity(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/CreatePDM_BodyCheck_TaoCanItemEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐项目中间表列表-------------------
export function GetPDM_BodyCheck_TaoCanItemList(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/GetPDM_BodyCheck_TaoCanItemList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取套餐项目中间表列表---------------
export function GetPDM_BodyCheck_TaoCanItemListByPage(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/GetPDM_BodyCheck_TaoCanItemListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取套餐项目中间表对象---------------
export function GetPDM_BodyCheck_TaoCanItemEntity(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/GetPDM_BodyCheck_TaoCanItemEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存套餐项目中间表对象---------------
export function UpdatePDM_BodyCheck_TaoCanItemEntity(parameter) {
    return axios({
        url: api._bodycheckTaocanitem+ "/UpdatePDM_BodyCheck_TaoCanItemEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉套餐项目中间表对象---------------
export function DeletePDM_BodyCheck_TaoCanItemEntity(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/DeletePDM_BodyCheck_TaoCanItemEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID获取排班时间---------------
export function GetPaiBanTimeData(parameter) {
    return axios({
        url: api._bodycheckTaocanitem + "/GetPaiBanTimeData",
        method: 'post',
        data: parameter
    })
}

